import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Video from "../components/video"
import Signup from '../components/signup';
import { S9comment } from 'gatsby-plugin-social9-comment'

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const image = getImage(post.frontmatter.featuredImage)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline" className="post-title">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <p>
          {post.frontmatter.tags.map((tag, index) => {
            return (
                <span key={index} className="tag">
                    <Link to={`/tags/${tag}`}>{tag}</Link>
                </span>
              )
            }
          )}
          </p>
        </header>
        {isVideoThere(post,image)}
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <script id="s9-sdk" async defer content="bcb9231f9a364d299ab333667c0b9b9b" src="//cdn.social9.com/js/socialshare.min.js"></script>
        <script defer src="https://social9.com/comments/js/s9comments.js"></script>
        <div class="s9-widget-wrapper"></div>
        <Signup />
        <footer>
          <Bio />
        </footer>
        <div id="s9comments"></div>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

const isVideoThere = (post, image) => {
  if(post.frontmatter.videoSourceURL && post.frontmatter.videoTitle){
    return (
      <Video
          videoSrcURL={post.frontmatter.videoSourceURL}
          videoTitle={post.frontmatter.videoTitle}
        />
    );
  } else {
    return (
      <GatsbyImage image={image} alt={post.frontmatter.title} placeholder="blurred" />
    );
  }
  
        
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        videoSourceURL
        videoTitle
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
