import React from "react"
import { videoContainer } from './video.module.css'
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className={videoContainer}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      width="600" 
      height="400"
      placeholder="blurred"
    />
  </div>
)
export default Video