import styled from 'styled-components';

export const EmailContainer = styled.div`
  margin-top: 10px;
  display: flex;
  width: 350px;
  margin-left: 10px;
`;

export const Input = styled.input`
  height: 50px;
  margin-top: 5px; 
`;

export const SendButton = styled.button`
  border: 1px solid gray;
  padding: 2px;
  background: #df7d2f;
  color: white;
  height: 50px;
  margin-top: 5px;
  
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;