import React, { Component } from 'react';
import {
  EmailContainer,
  Input,
  SendButton,
  Form
} from './mailchimpFormStyle';
import  { navigate } from 'gatsby';

import addToMailchimp from 'gatsby-plugin-mailchimp'

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '', FNAME: '', LNAME: '' };
    this._handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
  }
  _handleSubmit = async (e) => {
    e.preventDefault();
    const { email, FNAME, LNAME } = this.state;
    const result = await addToMailchimp(email, { FNAME, LNAME });
    console.log('result', result); 
    if(result.result === "success"){
      navigate('/success')
    }
    // I recommend setting `result` to React state
    // but you can do whatever you want
  }
  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }
  handleFirstNameChange(event) {
    this.setState({FNAME: event.target.value});
  }
  handleLastNameChange(event) {
    this.setState({LNAME: event.target.value});
  }
  render() {
    return (
      <EmailContainer>
        <Form action="/success" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={this._handleSubmit}>
            <Input 
              placeholder="First Name" 
              type="text" name="name"  
              value={this.state.FNAME} 
              onChange={this.handleFirstNameChange} 
              autoComplete="on"
            />
            <Input 
              placeholder="Last Name" 
              type="text" 
              name="family-name"
              value={this.state.LNAME} 
              onChange={this.handleLastNameChange}
              autoComplete="on"
            />
            <Input 
              placeholder="Example@gmail.com" 
              type="email" 
              name="email" 
              value={this.state.email} 
              onChange={this.handleEmailChange}
              autoComplete="on"
            />
            <input type="hidden" name="form-name" value="contact" />
            <SendButton type="submit">Send</SendButton>
        </Form>
      </EmailContainer>
    )
  }
}

export default SignupForm;