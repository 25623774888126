import React from 'react';
import styled from 'styled-components';
import SignupForm from './mailchimpForm';


const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  @media (min-width: 575px) {
    max-width: 540px;
  }
  @media (min-width: 767px) {
    max-width: 720px;
  }
  @media (min-width: 991px) {
    max-width: 960px;
  }
  @media (min-width: 1199px) {
    max-width: 1200px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Section = styled.section`
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 96px 0;
  }
  
`;

const H1 = styled.h1`
  color: #df7d2f;
`;

const Signup = () => (
    <Section id="brands" accent>
        <StyledContainer>
          <div>
            <H1>Sign up for my newsletter!</H1>
            <SignupForm />
          </div>
        </StyledContainer>
      </Section>
)

export default Signup;